// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResponsiveUIUtils_mobileOnly__1tQCt {\n  display: none !important;\n}\n\n@media (max-width: 1200px) {\n  .ResponsiveUIUtils_desktopOnly__2Fw6J {\n    display: none;\n  }\n\n  .ResponsiveUIUtils_mobileOnly__1tQCt {\n    display: inherit !important;\n  }\n}\n\n@media (max-width: 414px) {\n  .ResponsiveUIUtils_disableSmallScreens__1rorn {\n    display: none;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/utils/ResponsiveUIUtils.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".mobileOnly {\n  display: none !important;\n}\n\n@media (max-width: 1200px) {\n  .desktopOnly {\n    display: none;\n  }\n\n  .mobileOnly {\n    display: inherit !important;\n  }\n}\n\n@media (max-width: 414px) {\n  .disableSmallScreens {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileOnly": "ResponsiveUIUtils_mobileOnly__1tQCt",
	"desktopOnly": "ResponsiveUIUtils_desktopOnly__2Fw6J",
	"disableSmallScreens": "ResponsiveUIUtils_disableSmallScreens__1rorn"
};
export default ___CSS_LOADER_EXPORT___;
