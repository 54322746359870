// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FunctionCallReviewStep_label__303N8 {\n  opacity: 0.5;\n}\n\n.FunctionCallReviewStep_argument__28QrO {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 0.25rem;\n}\n\n.FunctionCallReviewStep_row__3xMHS {\n  display: flex;\n}\n\n.FunctionCallReviewStep_value__1Raj- {\n  font-size: 22px;\n  font-weight: bold;\n  color: var(--brand-cool-dark-text);\n  margin-bottom: 0.5rem;\n  word-break: break-all;\n}\n\n.FunctionCallReviewStep_argValue__3C2sL {\n  max-width: 50%;\n  word-break: break-all;\n}\n", "",{"version":3,"sources":["webpack://src/components/ProposalActionsModal/steps/FunctionCallReviewStep/FunctionCallReviewStep.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kCAAkC;EAClC,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB","sourcesContent":[".label {\n  opacity: 0.5;\n}\n\n.argument {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 0.25rem;\n}\n\n.row {\n  display: flex;\n}\n\n.value {\n  font-size: 22px;\n  font-weight: bold;\n  color: var(--brand-cool-dark-text);\n  margin-bottom: 0.5rem;\n  word-break: break-all;\n}\n\n.argValue {\n  max-width: 50%;\n  word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "FunctionCallReviewStep_label__303N8",
	"argument": "FunctionCallReviewStep_argument__28QrO",
	"row": "FunctionCallReviewStep_row__3xMHS",
	"value": "FunctionCallReviewStep_value__1Raj-",
	"argValue": "FunctionCallReviewStep_argValue__3C2sL"
};
export default ___CSS_LOADER_EXPORT___;
