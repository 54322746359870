// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileNounActivityRow_wrapper__3-L7o {\n  border-bottom: 1px solid var(--brand-gray-border);\n  padding-bottom: 0.5rem;\n  font-family: PT Root UI;\n  font-size: 18px;\n  margin-top: 1rem;\n  display: flex;\n}\n\n.MobileNounActivityRow_icon__3n-VO {\n  width: 38px;\n  margin-right: 0px;\n  margin-top: 0.25rem;\n  margin-left: 6px;\n}\n\n.MobileNounActivityRow_content__cEDSP {\n  margin-left: 1.5rem;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://src/components/profileEvent/activityRow/MobileNounActivityRow/MobileNounActivityRow.module.css"],"names":[],"mappings":"AAAA;EACE,iDAAiD;EACjD,sBAAsB;EACtB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB","sourcesContent":[".wrapper {\n  border-bottom: 1px solid var(--brand-gray-border);\n  padding-bottom: 0.5rem;\n  font-family: PT Root UI;\n  font-size: 18px;\n  margin-top: 1rem;\n  display: flex;\n}\n\n.icon {\n  width: 38px;\n  margin-right: 0px;\n  margin-top: 0.25rem;\n  margin-left: 6px;\n}\n\n.content {\n  margin-left: 1.5rem;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "MobileNounActivityRow_wrapper__3-L7o",
	"icon": "MobileNounActivityRow_icon__3n-VO",
	"content": "MobileNounActivityRow_content__cEDSP"
};
export default ___CSS_LOADER_EXPORT___;
