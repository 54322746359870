// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NounderNounContent_link__3MuKL,\n.NounderNounContent_link__3MuKL:visited,\n.NounderNounContent_link__3MuKL:active {\n  color: var(--brand-dark-green);\n  text-decoration: underline;\n}\n\n.NounderNounContent_link__3MuKL:hover {\n  color: var(--brand-dark-red);\n}\n\n.NounderNounContent_bidRow__2CVAB {\n  font-weight: 500;\n  font-size: 15.5px;\n  line-height: 21px;\n}\n\n.NounderNounContent_currentBidCol__3VQRX {\n  border-right: 0 !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/NounderNounContent/NounderNounContent.module.css"],"names":[],"mappings":"AAAA;;;EAGE,8BAA8B;EAC9B,0BAA0B;AAC5B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".link,\n.link:visited,\n.link:active {\n  color: var(--brand-dark-green);\n  text-decoration: underline;\n}\n\n.link:hover {\n  color: var(--brand-dark-red);\n}\n\n.bidRow {\n  font-weight: 500;\n  font-size: 15.5px;\n  line-height: 21px;\n}\n\n.currentBidCol {\n  border-right: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "NounderNounContent_link__3MuKL",
	"bidRow": "NounderNounContent_bidRow__2CVAB",
	"currentBidCol": "NounderNounContent_currentBidCol__3VQRX"
};
export default ___CSS_LOADER_EXPORT___;
