// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NounInfoRowBirthday_nounInfoRowBirthday__LxZeH {\n  font-family: 'PT Root UI';\n  font-weight: bold;\n  margin-left: 5px;\n}\n\n.NounInfoRowBirthday_birthdayInfoContainer__20t05 {\n  display: inline;\n  width: 350px;\n  height: 25px;\n}\n\n.NounInfoRowBirthday_birthdayIcon__1Ud68 {\n  margin-bottom: 4px;\n  margin-right: 7px;\n}\n", "",{"version":3,"sources":["webpack://src/components/NounInfoRowBirthday/NounInfoRowBirthday.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".nounInfoRowBirthday {\n  font-family: 'PT Root UI';\n  font-weight: bold;\n  margin-left: 5px;\n}\n\n.birthdayInfoContainer {\n  display: inline;\n  width: 350px;\n  height: 25px;\n}\n\n.birthdayIcon {\n  margin-bottom: 4px;\n  margin-right: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nounInfoRowBirthday": "NounInfoRowBirthday_nounInfoRowBirthday__LxZeH",
	"birthdayInfoContainer": "NounInfoRowBirthday_birthdayInfoContainer__20t05",
	"birthdayIcon": "NounInfoRowBirthday_birthdayIcon__1Ud68"
};
export default ___CSS_LOADER_EXPORT___;
