// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuctionActivityNounTitle_wrapper__2ia4b {\n  display: inline-block;\n}\n\n.AuctionActivityNounTitle_wrapper__2ia4b h1 {\n  font-family: 'Londrina Solid';\n  font-size: 68px;\n  margin-bottom: 10px;\n}\n\n@media (max-width: 992px) {\n  .AuctionActivityNounTitle_wrapper__2ia4b h1 {\n    font-size: 56px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/AuctionActivityNounTitle/AuctionActivityNounTitle.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".wrapper {\n  display: inline-block;\n}\n\n.wrapper h1 {\n  font-family: 'Londrina Solid';\n  font-size: 68px;\n  margin-bottom: 10px;\n}\n\n@media (max-width: 992px) {\n  .wrapper h1 {\n    font-size: 56px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AuctionActivityNounTitle_wrapper__2ia4b"
};
export default ___CSS_LOADER_EXPORT___;
