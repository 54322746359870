// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HorizontalStackedNouns_wrapper__2QZh9 {\n  position: relative !important;\n  margin-bottom: 50px;\n  margin-top: 10px;\n  margin-left: auto;\n  margin-right: auto;\n  width: 100%;\n}\n\n.HorizontalStackedNouns_nounWrapper__1HsPJ {\n  position: absolute;\n}\n", "",{"version":3,"sources":["webpack://src/components/HorizontalStackedNouns/HorizontalStackedNouns.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".wrapper {\n  position: relative !important;\n  margin-bottom: 50px;\n  margin-top: 10px;\n  margin-left: auto;\n  margin-right: auto;\n  width: 100%;\n}\n\n.nounWrapper {\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "HorizontalStackedNouns_wrapper__2QZh9",
	"nounWrapper": "HorizontalStackedNouns_nounWrapper__1HsPJ"
};
export default ___CSS_LOADER_EXPORT___;
