// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BrandSpinner_spinner__1s9t7 {\n  animation: BrandSpinner_rotate__QdcCR 1.25s linear infinite;\n  transform-origin: center center !important;\n}\n\n@keyframes BrandSpinner_rotate__QdcCR {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/BrandSpinner/BrandSpinner.module.css"],"names":[],"mappings":"AAAA;EACE,2DAAuC;EACvC,0CAA0C;AAC5C;;AAEA;EACE;IAEE,uBAAuB;EACzB;EACA;IAEE,yBAAyB;EAC3B;AACF","sourcesContent":[".spinner {\n  animation: rotate 1.25s linear infinite;\n  transform-origin: center center !important;\n}\n\n@keyframes rotate {\n  0% {\n    -webkit-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "BrandSpinner_spinner__1s9t7",
	"rotate": "BrandSpinner_rotate__QdcCR"
};
export default ___CSS_LOADER_EXPORT___;
