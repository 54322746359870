// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DesktopProposalVoteEvent_voteIcon__2wOOw {\n  width: 38px;\n  margin-right: 0px;\n  margin-bottom: 4px;\n  margin-left: 6px;\n}\n\n.DesktopProposalVoteEvent_proposalTitle__lgVjZ {\n  text-decoration: none;\n  color: black;\n  font-family: 'PT Root UI';\n  font-weight: bold;\n  cursor: pointer;\n}\n\n.DesktopProposalVoteEvent_delegateHover__1psVG {\n  border-radius: 8px !important;\n  background-color: var(--brand-gray-dark-text) !important;\n  color: white;\n  opacity: 0.75 !important;\n  font-weight: 500;\n  transition: ease-in-out 125ms;\n}\n", "",{"version":3,"sources":["webpack://src/components/profileEvent/event/DesktopProposalVoteEvent/DesktopProposalVoteEvent.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,wDAAwD;EACxD,YAAY;EACZ,wBAAwB;EACxB,gBAAgB;EAChB,6BAA6B;AAC/B","sourcesContent":[".voteIcon {\n  width: 38px;\n  margin-right: 0px;\n  margin-bottom: 4px;\n  margin-left: 6px;\n}\n\n.proposalTitle {\n  text-decoration: none;\n  color: black;\n  font-family: 'PT Root UI';\n  font-weight: bold;\n  cursor: pointer;\n}\n\n.delegateHover {\n  border-radius: 8px !important;\n  background-color: var(--brand-gray-dark-text) !important;\n  color: white;\n  opacity: 0.75 !important;\n  font-weight: 500;\n  transition: ease-in-out 125ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"voteIcon": "DesktopProposalVoteEvent_voteIcon__2wOOw",
	"proposalTitle": "DesktopProposalVoteEvent_proposalTitle__lgVjZ",
	"delegateHover": "DesktopProposalVoteEvent_delegateHover__1psVG"
};
export default ___CSS_LOADER_EXPORT___;
