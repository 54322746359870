// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotFound_heading__3xG74 {\n  display: inline-block;\n  font-weight: bold;\n  font-size: 3rem;\n}\n", "",{"version":3,"sources":["webpack://src/pages/NotFound/NotFound.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".heading {\n  display: inline-block;\n  font-weight: bold;\n  font-size: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "NotFound_heading__3xG74"
};
export default ___CSS_LOADER_EXPORT___;
