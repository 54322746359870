// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FunctionCallEnterArgsStep_inputGroupText__Y7q82 {\n  border-radius: 15px 0 0 15px;\n  border-right: none;\n  height: 3rem;\n  margin-top: 0.25rem;\n  margin-bottom: 0.5rem;\n  font-weight: 500;\n  opacity: 0.5;\n}\n\n.FunctionCallEnterArgsStep_label__5I1En {\n  opacity: 0.5;\n}\n\n.FunctionCallEnterArgsStep_inputGroup__2C6VO {\n  margin-bottom: 1rem;\n  border-radius: 15px;\n  width: 100%;\n  height: 3rem;\n  font-size: 22px;\n  font-weight: bold;\n  padding: 0.5rem 1rem;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  color: var(--brand-cool-dark-text);\n  margin-top: 0.25rem;\n  margin-bottom: 0.5rem;\n  outline: none;\n}\n\n.FunctionCallEnterArgsStep_invalid__2hBKd {\n  color: var(--brand-color-rd);\n}\n", "",{"version":3,"sources":["webpack://src/components/ProposalActionsModal/steps/FunctionCallEnterArgsStep/FunctionCallEnterArgsStep.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,qBAAqB;EACrB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,oCAAoC;EACpC,kCAAkC;EAClC,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".inputGroupText {\n  border-radius: 15px 0 0 15px;\n  border-right: none;\n  height: 3rem;\n  margin-top: 0.25rem;\n  margin-bottom: 0.5rem;\n  font-weight: 500;\n  opacity: 0.5;\n}\n\n.label {\n  opacity: 0.5;\n}\n\n.inputGroup {\n  margin-bottom: 1rem;\n  border-radius: 15px;\n  width: 100%;\n  height: 3rem;\n  font-size: 22px;\n  font-weight: bold;\n  padding: 0.5rem 1rem;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  color: var(--brand-cool-dark-text);\n  margin-top: 0.25rem;\n  margin-bottom: 0.5rem;\n  outline: none;\n}\n\n.invalid {\n  color: var(--brand-color-rd);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputGroupText": "FunctionCallEnterArgsStep_inputGroupText__Y7q82",
	"label": "FunctionCallEnterArgsStep_label__5I1En",
	"inputGroup": "FunctionCallEnterArgsStep_inputGroup__2C6VO",
	"invalid": "FunctionCallEnterArgsStep_invalid__2hBKd"
};
export default ___CSS_LOADER_EXPORT___;
