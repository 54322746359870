// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VoteCardPager_pageDots__2qKMa {\n  font-size: 24px;\n  font-weight: bold;\n  text-align: center;\n  color: var(--brand-gray-light-text);\n}\n\n.VoteCardPager_disabledPageDot__2Fj8y {\n  opacity: 0.5;\n}\n\n.VoteCardPager_paginationArrowsWrapper__16MKK {\n  display: flex;\n  justify-content: center;\n}\n\n.VoteCardPager_paginationArrowBtnWrapper__3cNKt {\n  border: none;\n  background-color: transparent;\n}\n\n.VoteCardPager_paginationArrowBtnWrapper__3cNKt:disabled {\n  opacity: 0.5;\n}\n\n.VoteCardPager_paginationArrow__3IBFg {\n  height: 28px;\n  width: 28px;\n  color: var(--brand-gray-light-text);\n}\n\n.VoteCardPager_disabled__7Pbuv {\n  opacity: 0.25;\n}\n", "",{"version":3,"sources":["webpack://src/components/VoteCardPager/VoteCardPager.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,mCAAmC;AACrC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mCAAmC;AACrC;;AAEA;EACE,aAAa;AACf","sourcesContent":[".pageDots {\n  font-size: 24px;\n  font-weight: bold;\n  text-align: center;\n  color: var(--brand-gray-light-text);\n}\n\n.disabledPageDot {\n  opacity: 0.5;\n}\n\n.paginationArrowsWrapper {\n  display: flex;\n  justify-content: center;\n}\n\n.paginationArrowBtnWrapper {\n  border: none;\n  background-color: transparent;\n}\n\n.paginationArrowBtnWrapper:disabled {\n  opacity: 0.5;\n}\n\n.paginationArrow {\n  height: 28px;\n  width: 28px;\n  color: var(--brand-gray-light-text);\n}\n\n.disabled {\n  opacity: 0.25;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageDots": "VoteCardPager_pageDots__2qKMa",
	"disabledPageDot": "VoteCardPager_disabledPageDot__2Fj8y",
	"paginationArrowsWrapper": "VoteCardPager_paginationArrowsWrapper__16MKK",
	"paginationArrowBtnWrapper": "VoteCardPager_paginationArrowBtnWrapper__3cNKt",
	"paginationArrow": "VoteCardPager_paginationArrow__3IBFg",
	"disabled": "VoteCardPager_disabled__7Pbuv"
};
export default ___CSS_LOADER_EXPORT___;
