// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProposalVoteInfoPills_wrapper__cn13c {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n@media (max-width: 992px) {\n  .ProposalVoteInfoPills_wrapper__cn13c {\n    justify-content: flex-start;\n  }\n}\n\n.ProposalVoteInfoPills_voteStatusWrapper__3eD-a {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-end;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://src/components/profileEvent/eventData/ProposalVoteInfoPillsContainer/ProposalVoteInfoPills.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n@media (max-width: 992px) {\n  .wrapper {\n    justify-content: flex-start;\n  }\n}\n\n.voteStatusWrapper {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-end;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ProposalVoteInfoPills_wrapper__cn13c",
	"voteStatusWrapper": "ProposalVoteInfoPills_voteStatusWrapper__3eD-a"
};
export default ___CSS_LOADER_EXPORT___;
