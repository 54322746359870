// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Link_link__3euZY,\n.Link_link__3euZY:hover,\n.Link_link__3euZY:active {\n  color: var(--brand-dark-red);\n}\n.Link_link__3euZY:hover {\n  text-decoration: underline;\n}\n", "",{"version":3,"sources":["webpack://src/components/Link/Link.module.css"],"names":[],"mappings":"AAAA;;;EAGE,4BAA4B;AAC9B;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".link,\n.link:hover,\n.link:active {\n  color: var(--brand-dark-red);\n}\n.link:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "Link_link__3euZY"
};
export default ___CSS_LOADER_EXPORT___;
