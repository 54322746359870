// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuctionActivityDateHeadline_date__3MSCC {\n  font-family: 'PT Root UI';\n  font-weight: bold;\n  font-size: 17px;\n  line-height: 27px;\n  margin-top: 0.22rem;\n}\n\n.AuctionActivityDateHeadline_wrapper__2YbIC {\n  margin-left: 5rem;\n  width: auto;\n}\n", "",{"version":3,"sources":["webpack://src/components/AuctionActivityDateHeadline/AuctionActivityDateHeadline.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".date {\n  font-family: 'PT Root UI';\n  font-weight: bold;\n  font-size: 17px;\n  line-height: 27px;\n  margin-top: 0.22rem;\n}\n\n.wrapper {\n  margin-left: 5rem;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": "AuctionActivityDateHeadline_date__3MSCC",
	"wrapper": "AuctionActivityDateHeadline_wrapper__2YbIC"
};
export default ___CSS_LOADER_EXPORT___;
