// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ABIUpload_wrapper__3E6Re {\n  margin-top: 1rem;\n}\n\n.ABIUpload_label__3Uedw {\n  opacity: 0.5;\n}\n\n.ABIUpload_form__urhzD {\n  border-radius: 15px;\n  width: 100%;\n  height: 3rem;\n  font-size: 22px;\n  font-weight: bold;\n  padding: 0.5rem 1rem;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  color: var(--brand-cool-dark-text);\n}\n", "",{"version":3,"sources":["webpack://src/components/ABIUpload/ABIUpload.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,oCAAoC;EACpC,kCAAkC;AACpC","sourcesContent":[".wrapper {\n  margin-top: 1rem;\n}\n\n.label {\n  opacity: 0.5;\n}\n\n.form {\n  border-radius: 15px;\n  width: 100%;\n  height: 3rem;\n  font-size: 22px;\n  font-weight: bold;\n  padding: 0.5rem 1rem;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  color: var(--brand-cool-dark-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ABIUpload_wrapper__3E6Re",
	"label": "ABIUpload_label__3Uedw",
	"form": "ABIUpload_form__urhzD"
};
export default ___CSS_LOADER_EXPORT___;
