// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileProposalVoteEvent_voteIcon__rlh4E {\n  width: 38px;\n  margin-right: 0px;\n  margin-bottom: 4px;\n  margin-left: 6px;\n}\n\n.MobileProposalVoteEvent_proposalTitle__3enqs {\n  text-decoration: none;\n  color: black;\n  font-family: 'PT Root UI';\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://src/components/profileEvent/event/MobileProposalVoteEvent/MobileProposalVoteEvent.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":[".voteIcon {\n  width: 38px;\n  margin-right: 0px;\n  margin-bottom: 4px;\n  margin-left: 6px;\n}\n\n.proposalTitle {\n  text-decoration: none;\n  color: black;\n  font-family: 'PT Root UI';\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"voteIcon": "MobileProposalVoteEvent_voteIcon__rlh4E",
	"proposalTitle": "MobileProposalVoteEvent_proposalTitle__3enqs"
};
export default ___CSS_LOADER_EXPORT___;
