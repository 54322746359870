// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-5-1!../../../node_modules/postcss-loader/src/index.js??postcss!./css/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_wrapper__2Rh_f {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  max-width: 100vw;\n}\n\n/* solution to allow for sticky sidebar on Explore page */\n@media (min-width: 993px) {\n  .App_wrapper__2Rh_f {\n    contain: paint;\n  }\n}\n@media (max-width: 992px) {\n  .App_wrapper__2Rh_f {\n    overflow-x: hidden;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/App.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA,yDAAyD;AACzD;EACE;IACE,cAAc;EAChB;AACF;AACA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["@import './css/colors.css';\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  max-width: 100vw;\n}\n\n/* solution to allow for sticky sidebar on Explore page */\n@media (min-width: 993px) {\n  .wrapper {\n    contain: paint;\n  }\n}\n@media (max-width: 992px) {\n  .wrapper {\n    overflow-x: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "App_wrapper__2Rh_f"
};
export default ___CSS_LOADER_EXPORT___;
