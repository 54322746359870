// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuctionTitleAndNavWrapper_auctionTitleAndNavContainer__2dUbM {\n  display: flex;\n}\n\n@media (max-width: 992px) {\n  .AuctionTitleAndNavWrapper_auctionTitleAndNavContainer__2dUbM h1 {\n    font-size: 2.75rem;\n  }\n}\n\n/* Fix Firefox navigation arrow alignment issues */\n@-moz-document url-prefix() {\n  .AuctionTitleAndNavWrapper_auctionTitleAndNavContainer__2dUbM {\n    display: flex;\n    align-items: center;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/AuctionTitleAndNavWrapper/AuctionTitleAndNavWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA,kDAAkD;AAClD;EACE;IACE,aAAa;IACb,mBAAmB;EACrB;AACF","sourcesContent":[".auctionTitleAndNavContainer {\n  display: flex;\n}\n\n@media (max-width: 992px) {\n  .auctionTitleAndNavContainer h1 {\n    font-size: 2.75rem;\n  }\n}\n\n/* Fix Firefox navigation arrow alignment issues */\n@-moz-document url-prefix() {\n  .auctionTitleAndNavContainer {\n    display: flex;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auctionTitleAndNavContainer": "AuctionTitleAndNavWrapper_auctionTitleAndNavContainer__2dUbM"
};
export default ___CSS_LOADER_EXPORT___;
