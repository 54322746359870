// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DelegateGroupedNounImageVoteTable_wrapper__GLPLV {\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: -20px;\n}\n\n.DelegateGroupedNounImageVoteTable_nounCell__QPC3S {\n  width: 55px;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://src/components/DelegateGroupedNounImageVoteTable/DelegateGroupedNounImageVoteTable.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".wrapper {\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: -20px;\n}\n\n.nounCell {\n  width: 55px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "DelegateGroupedNounImageVoteTable_wrapper__GLPLV",
	"nounCell": "DelegateGroupedNounImageVoteTable_nounCell__QPC3S"
};
export default ___CSS_LOADER_EXPORT___;
