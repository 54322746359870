// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 992px) {\n  .AuctionActivityWrapper_wrapper__1JNe0 {\n    margin-left: 1rem;\n    margin-right: 1rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/AuctionActivityWrapper/AuctionActivityWrapper.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;AACF","sourcesContent":["@media (max-width: 992px) {\n  .wrapper {\n    margin-left: 1rem;\n    margin-right: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AuctionActivityWrapper_wrapper__1JNe0"
};
export default ___CSS_LOADER_EXPORT___;
