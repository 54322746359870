// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProposalTransactions_popover__3fRG9 {\n  max-width: 600px;\n}\n\n.ProposalTransactions_transactionDetails__2EchO {\n  margin-top: 1rem;\n  padding: 0.5rem 1rem;\n  border: 1px solid #aaa !important;\n  border-radius: 8px !important;\n}\n\n.ProposalTransactions_removeTransactionButton__2dBl_ {\n  border: none;\n  background: none;\n  outline: none !important;\n  box-shadow: none !important;\n}\n\n.ProposalTransactions_removeTransactionButton__2dBl_ img {\n  width: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/ProposalTransactions/ProposalTransactions.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,iCAAiC;EACjC,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,wBAAwB;EACxB,2BAA2B;AAC7B;;AAEA;EACE,WAAW;AACb","sourcesContent":[".popover {\n  max-width: 600px;\n}\n\n.transactionDetails {\n  margin-top: 1rem;\n  padding: 0.5rem 1rem;\n  border: 1px solid #aaa !important;\n  border-radius: 8px !important;\n}\n\n.removeTransactionButton {\n  border: none;\n  background: none;\n  outline: none !important;\n  box-shadow: none !important;\n}\n\n.removeTransactionButton img {\n  width: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": "ProposalTransactions_popover__3fRG9",
	"transactionDetails": "ProposalTransactions_transactionDetails__2EchO",
	"removeTransactionButton": "ProposalTransactions_removeTransactionButton__2dBl_"
};
export default ___CSS_LOADER_EXPORT___;
