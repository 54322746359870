// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalBottomButtonRow_buttonWrapper__2sPN0 {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 2rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/ModalBottomButtonRow/ModalBottomButtonRow.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB","sourcesContent":[".buttonWrapper {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": "ModalBottomButtonRow_buttonWrapper__2sPN0"
};
export default ___CSS_LOADER_EXPORT___;
