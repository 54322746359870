// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalletConnectModal_clickable__2UYOs {\n  cursor: pointer;\n}\n\n.WalletConnectModal_walletConnectData__1PDIT {\n  font-size: 15px;\n  padding-top: 1.5rem;\n  padding-bottom: 0rem;\n  margin-bottom: -1.25rem;\n  opacity: 0.5;\n  transition: all 0.15s ease-in-out;\n}\n\n.WalletConnectModal_walletConnectData__1PDIT:hover {\n  opacity: 1;\n}\n", "",{"version":3,"sources":["webpack://src/components/WalletConnectModal/WalletConnectModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,oBAAoB;EACpB,uBAAuB;EACvB,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".clickable {\n  cursor: pointer;\n}\n\n.walletConnectData {\n  font-size: 15px;\n  padding-top: 1.5rem;\n  padding-bottom: 0rem;\n  margin-bottom: -1.25rem;\n  opacity: 0.5;\n  transition: all 0.15s ease-in-out;\n}\n\n.walletConnectData:hover {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickable": "WalletConnectModal_clickable__2UYOs",
	"walletConnectData": "WalletConnectModal_walletConnectData__1PDIT"
};
export default ___CSS_LOADER_EXPORT___;
