// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 992px) {\n  .SettleManuallyBtn_emergencySettleWrapper__1_H1Q {\n    text-align: center;\n    margin-left: 0.5rem;\n  }\n}\n\n@media (max-width: 660px) {\n  .SettleManuallyBtn_emergencySettleWrapper__1_H1Q {\n    text-align: center;\n    margin-left: 0rem;\n  }\n}\n\n.SettleManuallyBtn_emergencySettleButton__2ov3p {\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  text-decoration: underline;\n  display: inline;\n  margin: 0;\n  padding: 0;\n}\n.SettleManuallyBtn_emergencySettleButton__2ov3p:disabled {\n  color: #8c8d92;\n  text-decoration: none;\n  cursor: default;\n}\n", "",{"version":3,"sources":["webpack://src/components/SettleManuallyBtn/SettleManuallyBtn.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,iBAAiB;EACnB;AACF;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,0BAA0B;EAC1B,eAAe;EACf,SAAS;EACT,UAAU;AACZ;AACA;EACE,cAAc;EACd,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":["@media (max-width: 992px) {\n  .emergencySettleWrapper {\n    text-align: center;\n    margin-left: 0.5rem;\n  }\n}\n\n@media (max-width: 660px) {\n  .emergencySettleWrapper {\n    text-align: center;\n    margin-left: 0rem;\n  }\n}\n\n.emergencySettleButton {\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  text-decoration: underline;\n  display: inline;\n  margin: 0;\n  padding: 0;\n}\n.emergencySettleButton:disabled {\n  color: #8c8d92;\n  text-decoration: none;\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emergencySettleWrapper": "SettleManuallyBtn_emergencySettleWrapper__1_H1Q",
	"emergencySettleButton": "SettleManuallyBtn_emergencySettleButton__2ov3p"
};
export default ___CSS_LOADER_EXPORT___;
