// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NounInfoCard_nounInfoHeader__r6K6B {\n  display: inline-block;\n  padding-left: 0rem;\n  font-family: 'Londrina Solid';\n}\n\n@media only screen and (max-width: 600px) {\n  .NounInfoCard_nounInfoHeader__r6K6B {\n    padding-top: 1.5rem;\n    margin-bottom: 0;\n  }\n}\n\n.NounInfoCard_nounInfoHeader__r6K6B h2 {\n  font-size: 56px;\n  margin-bottom: 18px;\n}\n\n.NounInfoCard_nounInfoHeader__r6K6B h3 {\n  font-size: 24px;\n  margin-bottom: '0px';\n  color: #79809c;\n}\n\n.NounInfoCard_nounInfoRow__1iqd0 {\n  font-family: 'PT Root UI';\n  font-weight: 500;\n  padding-bottom: 0.5rem;\n  padding-top: 0.5rem;\n  font-size: 18px;\n  display: flex;\n}\n\n.NounInfoCard_nounInfoRowText__tGZn_ {\n  padding-left: 4;\n  font-family: 'PT Root UI';\n  font-family: bold;\n}\n", "",{"version":3,"sources":["webpack://src/components/NounInfoCard/NounInfoCard.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE;IACE,mBAAmB;IACnB,gBAAgB;EAClB;AACF;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":[".nounInfoHeader {\n  display: inline-block;\n  padding-left: 0rem;\n  font-family: 'Londrina Solid';\n}\n\n@media only screen and (max-width: 600px) {\n  .nounInfoHeader {\n    padding-top: 1.5rem;\n    margin-bottom: 0;\n  }\n}\n\n.nounInfoHeader h2 {\n  font-size: 56px;\n  margin-bottom: 18px;\n}\n\n.nounInfoHeader h3 {\n  font-size: 24px;\n  margin-bottom: '0px';\n  color: #79809c;\n}\n\n.nounInfoRow {\n  font-family: 'PT Root UI';\n  font-weight: 500;\n  padding-bottom: 0.5rem;\n  padding-top: 0.5rem;\n  font-size: 18px;\n  display: flex;\n}\n\n.nounInfoRowText {\n  padding-left: 4;\n  font-family: 'PT Root UI';\n  font-family: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nounInfoHeader": "NounInfoCard_nounInfoHeader__r6K6B",
	"nounInfoRow": "NounInfoCard_nounInfoRow__1iqd0",
	"nounInfoRowText": "NounInfoCard_nounInfoRowText__tGZn_"
};
export default ___CSS_LOADER_EXPORT___;
