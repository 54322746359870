// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileActivityFeedToggle_expandCollapseCopy__y9obe {\n  color: var(--brand-cool-light-text);\n  font-family: 'PT Root UI';\n  font-weight: bold;\n  font-size: 20px;\n  text-align: center;\n  cursor: pointer;\n  margin-top: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/ProfileActivityFeedToggle/ProfileActivityFeedToggle.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,yBAAyB;EACzB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".expandCollapseCopy {\n  color: var(--brand-cool-light-text);\n  font-family: 'PT Root UI';\n  font-weight: bold;\n  font-size: 20px;\n  text-align: center;\n  cursor: pointer;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandCollapseCopy": "ProfileActivityFeedToggle_expandCollapseCopy__y9obe"
};
export default ___CSS_LOADER_EXPORT___;
