// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StreamPaymentsReviewStep_hover__z1QHP {\n  border-radius: 8px !important;\n  background-color: var(--brand-gray-dark-text) !important;\n  color: white;\n  opacity: 0.75 !important;\n  font-weight: 500;\n  transition: ease-in-out 125ms;\n}\n", "",{"version":3,"sources":["webpack://src/components/ProposalActionsModal/steps/StreamPaymentsReviewStep/StreamPaymentsReviewStep.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,wDAAwD;EACxD,YAAY;EACZ,wBAAwB;EACxB,gBAAgB;EAChB,6BAA6B;AAC/B","sourcesContent":[".hover {\n  border-radius: 8px !important;\n  background-color: var(--brand-gray-dark-text) !important;\n  color: white;\n  opacity: 0.75 !important;\n  font-weight: 500;\n  transition: ease-in-out 125ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hover": "StreamPaymentsReviewStep_hover__z1QHP"
};
export default ___CSS_LOADER_EXPORT___;
