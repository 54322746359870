// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Section_container__1zQTr {\n  padding: 2rem 0rem 0rem 0rem;\n}\n", "",{"version":3,"sources":["webpack://src/layout/Section/Section.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B","sourcesContent":[".container {\n  padding: 2rem 0rem 0rem 0rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Section_container__1zQTr"
};
export default ___CSS_LOADER_EXPORT___;
