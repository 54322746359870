// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Explore_exploreWrap__DQX2P {\n  padding: 0;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  margin: 0 3vw;\n  border-radius: 10px;\n}\n\n.Explore_gridWrap__2Cfr9 {\n  width: 100%;\n  position: relative;\n  z-index: 2;\n}\n\n.Explore_detailBlock__2wi9F {\n  max-width: 500px;\n  flex: 0 0 33%;\n}\n\n.Explore_contentWrap__1Upll {\n  display: flex;\n  justify-content: space-between;\n}\n\n@media (max-width: 991px) {\n  .Explore_exploreWrap__DQX2P {\n    border: 0;\n    margin: 0 0.5rem;\n  }\n  .Explore_contentWrap__1Upll {\n    contain: none;\n    position: relative;\n    z-index: 2;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/pages/Explore/Explore.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,oCAAoC;EACpC,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE;IACE,SAAS;IACT,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,kBAAkB;IAClB,UAAU;EACZ;AACF","sourcesContent":[".exploreWrap {\n  padding: 0;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  margin: 0 3vw;\n  border-radius: 10px;\n}\n\n.gridWrap {\n  width: 100%;\n  position: relative;\n  z-index: 2;\n}\n\n.detailBlock {\n  max-width: 500px;\n  flex: 0 0 33%;\n}\n\n.contentWrap {\n  display: flex;\n  justify-content: space-between;\n}\n\n@media (max-width: 991px) {\n  .exploreWrap {\n    border: 0;\n    margin: 0 0.5rem;\n  }\n  .contentWrap {\n    contain: none;\n    position: relative;\n    z-index: 2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exploreWrap": "Explore_exploreWrap__DQX2P",
	"gridWrap": "Explore_gridWrap__2Cfr9",
	"detailBlock": "Explore_detailBlock__2wi9F",
	"contentWrap": "Explore_contentWrap__1Upll"
};
export default ___CSS_LOADER_EXPORT___;
