// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalletButton_walletButton__Q_aK0 {\n  border: none;\n  margin: 5px;\n  padding: 5px 20px;\n  border-radius: 5px;\n  background-color: rgba(211, 211, 211, 0.664);\n  color: var(--brand-black);\n  border: none;\n}\n.WalletButton_walletButton__Q_aK0 img {\n  margin-right: 10px;\n  border-radius: 5px;\n}\n.WalletButton_walletButton__Q_aK0:hover,\n.WalletButton_walletButton__Q_aK0:active,\n.WalletButton_walletButton__Q_aK0:focus,\n.WalletButton_walletButton__Q_aK0:disabled {\n  outline: none !important;\n  box-shadow: none !important;\n  background-color: lightgray !important;\n  color: var(--brand-dark-green);\n  color: white;\n}\n", "",{"version":3,"sources":["webpack://src/components/WalletButton/WalletButton.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,4CAA4C;EAC5C,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;AACA;;;;EAIE,wBAAwB;EACxB,2BAA2B;EAC3B,sCAAsC;EACtC,8BAA8B;EAC9B,YAAY;AACd","sourcesContent":[".walletButton {\n  border: none;\n  margin: 5px;\n  padding: 5px 20px;\n  border-radius: 5px;\n  background-color: rgba(211, 211, 211, 0.664);\n  color: var(--brand-black);\n  border: none;\n}\n.walletButton img {\n  margin-right: 10px;\n  border-radius: 5px;\n}\n.walletButton:hover,\n.walletButton:active,\n.walletButton:focus,\n.walletButton:disabled {\n  outline: none !important;\n  box-shadow: none !important;\n  background-color: lightgray !important;\n  color: var(--brand-dark-green);\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletButton": "WalletButton_walletButton__Q_aK0"
};
export default ___CSS_LOADER_EXPORT___;
