// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BrandNumericEntry_container__2CdPJ {\n  width: 100%;\n  margin-top: 1rem;\n  position: relative;\n}\n\n.BrandNumericEntry_label__1jj-T {\n  opacity: 0.5;\n}\n\n.BrandNumericEntry_entry__3VxYD {\n  border-radius: 15px;\n  width: 100%;\n  height: 3rem;\n  font-size: 22px;\n  font-weight: bold;\n  padding: 0.5rem 1rem;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  color: var(--brand-cool-dark-text);\n  margin-top: 0.25rem;\n  margin-bottom: 0.5rem;\n  outline: none;\n  transition: all ease-in-out 125;\n}\n\n.BrandNumericEntry_invalid__360z8 {\n  border: 2px solid var(--brand-color-red) !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/BrandNumericEntry/BrandNumericEntry.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,oCAAoC;EACpC,kCAAkC;EAClC,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;EACb,+BAA+B;AACjC;;AAEA;EACE,mDAAmD;AACrD","sourcesContent":[".container {\n  width: 100%;\n  margin-top: 1rem;\n  position: relative;\n}\n\n.label {\n  opacity: 0.5;\n}\n\n.entry {\n  border-radius: 15px;\n  width: 100%;\n  height: 3rem;\n  font-size: 22px;\n  font-weight: bold;\n  padding: 0.5rem 1rem;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  color: var(--brand-cool-dark-text);\n  margin-top: 0.25rem;\n  margin-bottom: 0.5rem;\n  outline: none;\n  transition: all ease-in-out 125;\n}\n\n.invalid {\n  border: 2px solid var(--brand-color-red) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BrandNumericEntry_container__2CdPJ",
	"label": "BrandNumericEntry_label__1jj-T",
	"entry": "BrandNumericEntry_entry__3VxYD",
	"invalid": "BrandNumericEntry_invalid__360z8"
};
export default ___CSS_LOADER_EXPORT___;
