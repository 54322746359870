// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NounImageVoteTable_wrapper__TMwqB {\n  margin-left: 0.25rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/NounImageVoteTable/NounImageVoteTable.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB","sourcesContent":[".wrapper {\n  margin-left: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "NounImageVoteTable_wrapper__TMwqB"
};
export default ___CSS_LOADER_EXPORT___;
