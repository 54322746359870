// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalTextPrimary_text__dzV8G {\n  font-size: 22px;\n  font-weight: bold;\n  color: var(--brand-cool-dark-text);\n  margin-bottom: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/ModalTextPrimary/ModalTextPrimary.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,kCAAkC;EAClC,qBAAqB;AACvB","sourcesContent":[".text {\n  font-size: 22px;\n  font-weight: bold;\n  color: var(--brand-cool-dark-text);\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "ModalTextPrimary_text__dzV8G"
};
export default ___CSS_LOADER_EXPORT___;
