// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StandaloneNoun_clickableNoun__15g6a {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://src/components/StandaloneNoun/StandaloneNoun.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB","sourcesContent":[".clickableNoun {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickableNoun": "StandaloneNoun_clickableNoun__15g6a"
};
export default ___CSS_LOADER_EXPORT___;
