// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileTransferEvent_switchIconWrapper__SSRX1 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: var(--brand-gray-light-text-translucent);\n  color: var(--brand-gray-light-text);\n  border-radius: 100%;\n  height: 38px;\n  width: 38px;\n  margin-left: 0.4rem;\n}\n\n.MobileTransferEvent_switchIcon__W1ac2 {\n  height: 22px;\n  width: 22px;\n}\n\n.MobileTransferEvent_bold__3gKys {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://src/components/profileEvent/event/MobileTransferEvent/MobileTransferEvent.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,0DAA0D;EAC1D,mCAAmC;EACnC,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".switchIconWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: var(--brand-gray-light-text-translucent);\n  color: var(--brand-gray-light-text);\n  border-radius: 100%;\n  height: 38px;\n  width: 38px;\n  margin-left: 0.4rem;\n}\n\n.switchIcon {\n  height: 22px;\n  width: 22px;\n}\n\n.bold {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switchIconWrapper": "MobileTransferEvent_switchIconWrapper__SSRX1",
	"switchIcon": "MobileTransferEvent_switchIcon__W1ac2",
	"bold": "MobileTransferEvent_bold__3gKys"
};
export default ___CSS_LOADER_EXPORT___;
