// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalLabel_label__AjBPO {\n  opacity: 0.5;\n}\n", "",{"version":3,"sources":["webpack://src/components/ModalLabel/ModalLabel.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd","sourcesContent":[".label {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "ModalLabel_label__AjBPO"
};
export default ___CSS_LOADER_EXPORT___;
