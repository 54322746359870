// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VoteProgressBar_wrapper__a8wkH {\n  border-radius: 6px;\n  height: 1rem;\n}\n\n.VoteProgressBar_progressBar__35-Ke {\n  height: 100%;\n  border-radius: 6px;\n}\n\n.VoteProgressBar_forWrapper__RjvRz {\n  background-color: var(--brand-color-green-translucent);\n}\n\n.VoteProgressBar_forProgressBar__3JlTZ {\n  background-color: var(--brand-color-green);\n}\n\n.VoteProgressBar_againstWrapper__IukLN {\n  background-color: var(--brand-color-red-translucent);\n}\n\n.VoteProgressBar_againstProgressBar__15RmJ {\n  background-color: var(--brand-color-red);\n}\n\n.VoteProgressBar_abstainWrapper__13Z8S {\n  background-color: var(--brand-gray-light-text-translucent);\n}\n\n.VoteProgressBar_abstainProgressBar__HzhI2 {\n  background-color: var(--brand-gray-light-text);\n}\n", "",{"version":3,"sources":["webpack://src/components/VoteProgressBar/VoteProgressBar.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,sDAAsD;AACxD;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,0DAA0D;AAC5D;;AAEA;EACE,8CAA8C;AAChD","sourcesContent":[".wrapper {\n  border-radius: 6px;\n  height: 1rem;\n}\n\n.progressBar {\n  height: 100%;\n  border-radius: 6px;\n}\n\n.forWrapper {\n  background-color: var(--brand-color-green-translucent);\n}\n\n.forProgressBar {\n  background-color: var(--brand-color-green);\n}\n\n.againstWrapper {\n  background-color: var(--brand-color-red-translucent);\n}\n\n.againstProgressBar {\n  background-color: var(--brand-color-red);\n}\n\n.abstainWrapper {\n  background-color: var(--brand-gray-light-text-translucent);\n}\n\n.abstainProgressBar {\n  background-color: var(--brand-gray-light-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "VoteProgressBar_wrapper__a8wkH",
	"progressBar": "VoteProgressBar_progressBar__35-Ke",
	"forWrapper": "VoteProgressBar_forWrapper__RjvRz",
	"forProgressBar": "VoteProgressBar_forProgressBar__3JlTZ",
	"againstWrapper": "VoteProgressBar_againstWrapper__IukLN",
	"againstProgressBar": "VoteProgressBar_againstProgressBar__15RmJ",
	"abstainWrapper": "VoteProgressBar_abstainWrapper__13Z8S",
	"abstainProgressBar": "VoteProgressBar_abstainProgressBar__HzhI2"
};
export default ___CSS_LOADER_EXPORT___;
