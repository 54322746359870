// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HoverCard_hover__2x2iv {\n  box-sizing: border-box;\n  min-width: 217px;\n  width: max-content;\n  height: -moz-fit-content;\n  height: fit-content;\n  left: 301px;\n  top: 396px;\n\n  /* White */\n  background-color: #ffffff !important;\n\n  /* Gray/Border */\n  border: 1px solid #e2e3e8 !important;\n  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.01) !important;\n  border-radius: 14px !important;\n  color: black !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/HoverCard/HoverCard.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,wBAAmB;EAAnB,mBAAmB;EACnB,WAAW;EACX,UAAU;;EAEV,UAAU;EACV,oCAAoC;;EAEpC,gBAAgB;EAChB,oCAAoC;EACpC,sDAAsD;EACtD,8BAA8B;EAC9B,uBAAuB;AACzB","sourcesContent":[".hover {\n  box-sizing: border-box;\n  min-width: 217px;\n  width: max-content;\n  height: fit-content;\n  left: 301px;\n  top: 396px;\n\n  /* White */\n  background-color: #ffffff !important;\n\n  /* Gray/Border */\n  border: 1px solid #e2e3e8 !important;\n  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.01) !important;\n  border-radius: 14px !important;\n  color: black !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hover": "HoverCard_hover__2x2iv"
};
export default ___CSS_LOADER_EXPORT___;
