// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DesktopProfileActivityFeed_aboveTheFoldEventsTable__2UIky {\n  margin-bottom: 0rem;\n}\n\n.DesktopProfileActivityFeed_aboveTheFoldEventsTable__2UIky td {\n  border-top: 1px solid rgba(0, 0, 0, 0.1);\n  padding-top: 12px;\n}\n\n.DesktopProfileActivityFeed_nounInfoPadding__2Bhvn {\n  padding-bottom: 1rem;\n  font-size: 18px;\n}\n", "",{"version":3,"sources":["webpack://src/components/DesktopProfileActivityFeed/DesktopProfileActivityFeed.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,wCAAwC;EACxC,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,eAAe;AACjB","sourcesContent":[".aboveTheFoldEventsTable {\n  margin-bottom: 0rem;\n}\n\n.aboveTheFoldEventsTable td {\n  border-top: 1px solid rgba(0, 0, 0, 0.1);\n  padding-top: 12px;\n}\n\n.nounInfoPadding {\n  padding-bottom: 1rem;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboveTheFoldEventsTable": "DesktopProfileActivityFeed_aboveTheFoldEventsTable__2UIky",
	"nounInfoPadding": "DesktopProfileActivityFeed_nounInfoPadding__2Bhvn"
};
export default ___CSS_LOADER_EXPORT___;
