// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TransferFundsReviewStep_label__3SmEg {\n  opacity: 0.5;\n}\n\n.TransferFundsReviewStep_text__2SCk3 {\n  font-size: 22px;\n  font-weight: bold;\n  color: var(--brand-cool-dark-text);\n  margin-bottom: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/ProposalActionsModal/steps/TransferFundsReviewStep/TransferFundsReviewStep.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kCAAkC;EAClC,qBAAqB;AACvB","sourcesContent":[".label {\n  opacity: 0.5;\n}\n\n.text {\n  font-size: 22px;\n  font-weight: bold;\n  color: var(--brand-cool-dark-text);\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "TransferFundsReviewStep_label__3SmEg",
	"text": "TransferFundsReviewStep_text__2SCk3"
};
export default ___CSS_LOADER_EXPORT___;
