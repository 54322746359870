// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalSubtitle_subtitle__30u4J {\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://src/components/ModalSubtitle/ModalSubtitle.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB","sourcesContent":[".subtitle {\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "ModalSubtitle_subtitle__30u4J"
};
export default ___CSS_LOADER_EXPORT___;
