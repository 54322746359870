// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DelegationCandidateVoteCountInfo_wrapper__33_OY {\n  display: flex;\n}\n\n.DelegationCandidateVoteCountInfo_spinner__206ux {\n  margin-right: 0.5rem;\n  margin-top: 0.5rem;\n}\n\n.DelegationCandidateVoteCountInfo_voteInfoWrapper__12v0n {\n  display: flex;\n  flex-direction: column;\n  font-weight: normal;\n  color: var(--brand-gray-light-text);\n  text-align: right;\n}\n\n.DelegationCandidateVoteCountInfo_voteCount__2Wck1 {\n  color: black;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://src/components/DelegationCandidateVoteCountInfo/DelegationCandidateVoteCountInfo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mCAAmC;EACnC,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".wrapper {\n  display: flex;\n}\n\n.spinner {\n  margin-right: 0.5rem;\n  margin-top: 0.5rem;\n}\n\n.voteInfoWrapper {\n  display: flex;\n  flex-direction: column;\n  font-weight: normal;\n  color: var(--brand-gray-light-text);\n  text-align: right;\n}\n\n.voteCount {\n  color: black;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "DelegationCandidateVoteCountInfo_wrapper__33_OY",
	"spinner": "DelegationCandidateVoteCountInfo_spinner__206ux",
	"voteInfoWrapper": "DelegationCandidateVoteCountInfo_voteInfoWrapper__12v0n",
	"voteCount": "DelegationCandidateVoteCountInfo_voteCount__2Wck1"
};
export default ___CSS_LOADER_EXPORT___;
