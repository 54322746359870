// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DelegationCandidateInfo_spinner__qInM2 {\n  display: flex;\n  justify-content: center;\n}\n\n.DelegationCandidateInfo_wrapper__1Tkvz {\n  display: flex;\n  flex-direction: row;\n  margin-top: 0.75rem;\n  justify-content: space-between;\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n}\n\n.DelegationCandidateInfo_delegateCandidateInfoWrapper__1p1rm {\n  display: flex;\n}\n\n.DelegationCandidateInfo_avatarWrapper__1eNEU {\n  margin-right: 1rem;\n}\n\n.DelegationCandidateInfo_ensText__wHrDa {\n  color: var(--brand-cool-dark-text);\n  font-weight: bold;\n  font-size: 22px;\n}\n\n.DelegationCandidateInfo_shortAddress__2qjqH {\n  font-weight: 500;\n  font-size: 13px;\n  color: var(--brand-cool-light-text);\n}\n", "",{"version":3,"sources":["webpack://src/components/DelegationCandidateInfo/DelegationCandidateInfo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;EAClC,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,mCAAmC;AACrC","sourcesContent":[".spinner {\n  display: flex;\n  justify-content: center;\n}\n\n.wrapper {\n  display: flex;\n  flex-direction: row;\n  margin-top: 0.75rem;\n  justify-content: space-between;\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n}\n\n.delegateCandidateInfoWrapper {\n  display: flex;\n}\n\n.avatarWrapper {\n  margin-right: 1rem;\n}\n\n.ensText {\n  color: var(--brand-cool-dark-text);\n  font-weight: bold;\n  font-size: 22px;\n}\n\n.shortAddress {\n  font-weight: 500;\n  font-size: 13px;\n  color: var(--brand-cool-light-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "DelegationCandidateInfo_spinner__qInM2",
	"wrapper": "DelegationCandidateInfo_wrapper__1Tkvz",
	"delegateCandidateInfoWrapper": "DelegationCandidateInfo_delegateCandidateInfoWrapper__1p1rm",
	"avatarWrapper": "DelegationCandidateInfo_avatarWrapper__1eNEU",
	"ensText": "DelegationCandidateInfo_ensText__wHrDa",
	"shortAddress": "DelegationCandidateInfo_shortAddress__2qjqH"
};
export default ___CSS_LOADER_EXPORT___;
